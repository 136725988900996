import { createTheme } from "@material-ui/core/styles"

const textMargins = {
  marginTop: 16,
  marginBottom: 16,
}

export const siteTheme = createTheme({
  palette: {
    primary: {
      main: "#476987",
      light: "#7597b7",
      dark: "#183f5a",
    },
    secondary: {
      main: "#92e5ff",
      light: "#c6ffff",
      dark: "#5eb3cc",
    },
  },
  typography: {
    h1: {
      ...textMargins,
    },
    h2: {
      ...textMargins,
    },
    h3: {
      ...textMargins,
    },
    h4: {
      ...textMargins,
    },
    h5: {
      ...textMargins,
    },
    h6: {
      ...textMargins,
    },
    body1: {
      ...textMargins,
    },
  },
})
