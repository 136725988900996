import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { AppBar, Toolbar, Typography, useTheme } from "@material-ui/core/index"

const Header = ({ siteTitle }: { siteTitle: string }) => {
  const theme = useTheme()

  return (
    <header>
      <AppBar position={"sticky"}>
        <Toolbar>
          <Typography variant={"h4"}>
            <Link
              to="/"
              style={{
                color: theme.palette.primary.contrastText,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
